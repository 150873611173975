var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_vm.overview),function(item){return [_c('section',{key:item.id},[_c('h2',{key:item.id},[_vm._v(_vm._s(item.header))]),_c('div',{key:item.id,staticClass:"row"},[_vm._l((item.dataTiles),function(item,index){return [_c('div',{key:item.id,staticClass:"col-lg-6 col-12 my-1 px-0",class:index % 2 ? 'pr-lg-0 pl-lg-2' : 'pl-lg-0 pr-lg-2'},[_c('base-data-tile',{key:item.id,staticClass:"shadow-none",attrs:{"tile-label":item.label,"tile-value":item.value,"tile-value-second":item.valueSecond,"tile-link":item.link,"input-list":item.inputList,"tile-link-value":item.linkValue,"tile-link-label":item.linkLabel}})],1)]})],2)]),_c('hr',{key:item.id,staticClass:"major bg-doe-secondary m-0 mb-4"})]}),_c('section',[_c('h2',{staticClass:"doe-dark-blue"},[_vm._v("Academic Offerings")]),(
        _vm.schoolAbout &&
        _vm.schoolAbout.earlyEDAcademicOfferingList &&
        _vm.schoolAbout.earlyEDAcademicOfferingList.length !== 0
      )?_c('div',{staticClass:"row"},[_vm._l((_vm.schoolAbout.earlyEDAcademicOfferingList),function(item,index){return [_c('div',{key:index,staticClass:"col-6 pl-0"},[_c('i',{staticClass:"fas fa-check mx-2 text-success fa-fw"}),_c('span',[_vm._v(_vm._s(item))]),(
              item !==
              _vm.academicOfferingsArray[_vm.academicOffering.GiftedPrograms].name
            )?_c('base-info-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-Info",modifiers:{"sidebar-Info":true}}],staticClass:"ml-2",on:{"info-click":function($event){return _vm.showModal(item)}}}):_vm._e()],1)]})],2):_c('div',[_vm._v("N/A")])]),_vm._l((_vm.additionalSections),function(item){return [_c('hr',{key:item.id,staticClass:"major doe-secondary"}),_c('section',{key:item.secondId},[_c('h2',{staticClass:"doe-dark-blue"},[_vm._v(_vm._s(item.header))]),(item.data.length !== 0)?_c('div',{staticClass:"row"},[_vm._l((item.data),function(item,index){return [(item !== '')?_c('div',{key:index,staticClass:"col-6 pl-0"},[_c('span',{key:index},[_c('i',{staticClass:"fas fa-check ml-2 text-success fa-fw"}),_vm._v(" "+_vm._s(item))])]):_vm._e()]})],2):_c('div',[_vm._v(" Most early childhood programs offer music and art in the classroom. ")])])]}),_c('b-sidebar',{attrs:{"id":"sidebar-Info","title":"","right":"","shadow":"","width":_vm.sidebarWidth,"no-header":true},scopedSlots:_vm._u([{key:"footer",fn:function({ hide }){return [_c('div',{staticClass:"text-light px-3 py-2 text-right bg-doe-light-violet"},[_c('span',{staticClass:"btn span-doe-close-style",on:{"click":hide}},[_c('i',{staticClass:"fas fa-times fa-fw"}),_vm._v(" Close ")])])]}}])},[_c('div',{staticClass:"text-light px-3 py-2 bg-doe-light-violet"},[_c('h5',[_vm._v(_vm._s(_vm.selectedHeaderText))])]),_c('div',{staticClass:"px-3 py-2"},[_vm._l((this.dataList),function(item,index){return [_c('div',{key:index,staticClass:"doe-dark-blue"},[_c('p',{key:index,domProps:{"innerHTML":_vm._s('<li>' + item + '</li>')}})])]})],2)]),_c('base-modal',{attrs:{"id":"modal","header-text":_vm.selectedHeaderText,"modal-type":_vm.BaseModalType.Data,"data-list":_vm.dataList,"no-close-on-backdrop":"","hide-footer":""}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }