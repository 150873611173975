
import { Vue, Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { K12School } from "@/models/interface";
import {
  AcademicOffering,
  academicOfferingsArray,
  BaseModalType,
} from "@/models/schoolfinder.enums";
import { startCase } from "lodash";
@Component({
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
  metaInfo: {
    title: "About Page",
  },
})
export default class ECAbout extends Vue {
  school!: K12School;
  infoPanelProps!: Map<string, string>;
  mobileView!: boolean;
  tabletView!: boolean;

  showItemDetail = false;
  dataList = [];

  selectedHeaderText = "";
  academicOfferingsArray = academicOfferingsArray;
  academicOffering = AcademicOffering;
  IsCareFacility=false;
  BaseModalType = BaseModalType;

  showModal(academicOffering: string) {
    this.selectedHeaderText = academicOffering;

    switch (academicOffering) {
      case academicOfferingsArray[AcademicOffering.AdvancedPlacement].name:
        this.dataList = this.schoolAbout.advancedPlacementList;
        break;
      case academicOfferingsArray[AcademicOffering.WorldLanguageImmersion].name:
        this.dataList = this.schoolAbout.wliList;
        break;
      case academicOfferingsArray[AcademicOffering.SealOfBiliteracy].name:
        this.dataList = this.schoolAbout.sealOfBiliteracyList;
        break;
      case academicOfferingsArray[AcademicOffering.DualEnrollment].name:
        this.dataList = this.schoolAbout.dualEnrollmentList;
        break;
      case academicOfferingsArray[AcademicOffering.JumpStartPathways].name:
        this.dataList = this.schoolAbout.jumpStartList;
        break;
      case academicOfferingsArray[AcademicOffering.ForeignLanguage].name:
        this.dataList = this.schoolAbout.foreignLangOfferedList;
        break;
      default:
        this.dataList = [];
        break;
    }

    this.showItemDetail = true;
  }
 async mounted(){
  await this.showInspection();
 if(this.IsCareFacility)this.schoolAbout.inspectionUrl=`/schools/${this.$route.params.schoolId}/Inspections`;
 }
  async  showInspection() {
   const result= await this.$store.dispatch("app/getIsCareFacilityApplicable", "IsCareFacility");
   this.IsCareFacility = result == "Y" ? true : false  ;
    return this.IsCareFacility;
  }

  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  get overview() {
    if (!this.schoolAbout) return [];

    const items = [
      {
        header: "Overview",
        dataTiles: [
          {
            id: 1,
            label: "Ages / Grades Served",
            value: this.gradesServed,
            valueSecond: this.gradesServedCurrent,
          },
          {
            id: 2,
            label: "Director / Principal",
            value: this.schoolAbout.earlyEdDirectorName,
            valueSecond: this.schoolAbout.earlyEdDirectorNameII,
          },
          {
            id: 3,
            label: "District / Parish",
            value: this.schoolAbout.districtName,
          },
          {
            id: 4,
            label: "Transportation",
            value: this.capitalizeFirstLetter(
              this.schoolAbout.earlyEdTransportationBinaryFormatted.toLowerCase()
            ),
          },
          {
            id: 5,
            label: "School Type",
            inputList: this.schoolAbout.earlyEdSchoolTypeList,
          },
          {
            id: 6,
            label: "How to Enroll",
            link: this.schoolAbout.eceEnrollmentlink,
          },
          {
            id: 7,
            label: "Takes Child Care Assistance Program (CCAP)",
            value: this.capitalizeFirstLetter(
              this.schoolAbout.earlyEdCcapAvailableBinary.toLowerCase()
            ),
          },
          {
            id: 8,
            label: "License Type",
            value: this.schoolAbout.earlyEdLicenseType,
          },
          {
            id: 9,
            label: "Inspection Visit Information",
            linkValue: this.schoolAbout.inspectionUrl,
            linkLabel: "Click here to view inspection visit information",
          },
          {
            id: 10,
            label: "Before Care",
            value: this.capitalizeFirstLetter(
              this.schoolAbout.beforeCareBinary.toLowerCase()
            ),
          },
          {
            id: 11,
            label: "Inspection Areas",
            linkValue: this.schoolAbout.inspectionList,
            linkLabel: "Click here to view inspection list information",
          },
          {
            id: 12,
            label: "After Care",
            value: this.capitalizeFirstLetter(
              this.schoolAbout.afterCareBinary.toLowerCase()
            ),
          },
          {
            id: 13,
            label: "Serious Injuries",
            linkValue: this.schoolAbout.seriousInjuriesUrl,
            linkLabel: "Click here to view serious injuries information",
          },
        ],
      },
      {
        header: "Additional Information",
        dataTiles: [
          {
            id: 1,
            label: "Total Student Enrollment",
            value: this.schoolAbout.totalEnrollmentFormattedNA,
          },
          {
            id: 2,
            label: "Current Early Education Capacity",
            value: this.schoolAbout.publiclyFundedChildrenEnrolledFormattedNA,
          },
        ],
      },
    ];
   
    return items.map((item) => {
      return {
        header: item.header,
        dataTiles: item.dataTiles.filter(
          (y) =>
            y.value ||
            (y.inputList && y.inputList.length > 0) ||
            y.link ||
            y.linkValue
        ),
      };
    });
  }

  capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  get additionalSections() {
    if (!this.schoolAbout) return [];

    return [
      {
        id: 1,
        secondId: 1.1,
        header: "Music / Art",
        data: this.schoolAbout.earlyEDMusicArtList,
      },
      // {
      //   id: 2,
      //   secondId: 2.1,
      //   header: "After-School Opportunities and Clubs",
      //   data: this.schoolAbout.clubsList,
      // },
      // {
      //   id: 3,
      //   secondId: 3.1,
      //   header: "Sports",
      //   data: this.schoolAbout.sportsList,
      // },
    ];
  }

  get schoolAbout() {
    return this.school && this.school.schoolAbout;
  }

  get compareGrades() {
    return (
      this.schoolAbout &&
      this.schoolAbout.gradeservedcurrentsy ===
        this.schoolAbout.gradeservedprevioussy
    );
  }

  get gradesServed() {
    let result;
    if (this.compareGrades) result = this.schoolAbout.gradeservedprevioussy;
    else
      result =
        this.schoolAbout.gradeservedprevioussy.trim() != ""
          ? this.schoolAbout.gradeservedprevioussy +
            " (" +
            this.schoolAbout.previousSchoolYear +
            ")"
          : " ";
    return result;
  }

  /* get schoolYear() {
    return this.$store.state.app.schoolYear;
  } */

  get gradesServedCurrent() {
    let result;
    if (this.compareGrades) result = "";
    else
      result =
        this.schoolAbout.gradeservedcurrentsy +
        " (" +
        this.schoolAbout.currentSchoolYear +
        ")";
    return result;
  }
}
